body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #f3f3f3;
}

.leaflet-container {
  width: 100%;
  height: calc(100vh - 150px);
}

.small-note {
    font-size: 14px;
    font-weight: 600;
    color: #A3A3A3;
    margin-bottom: 0;
}

.test {
    color: aquamarine;
}

.point {
    background-color: green;
}

.leaflet-bar {
  right: 334px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.40);
}

@media screen and (min-width: 1200px)  {
  .MuiDialog-paperWidthSm {
    max-width: 50% !important;
    min-width: 50% !important;
  }
}

@media screen and (max-width: 1200px)  {
  .MuiDialog-paperWidthSm {
    max-width: 70% !important;
    min-width: 70% !important;
  }
}

.MuiDialog-root {
  z-index: 1100 !important;
}

.react-images__view-image {
  background-color: white;
  height: 80vh !important;
  width: auto;    
  max-width: 100%!important;
  object-fit: contain;
}

.gallery-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gallery-image {
  height: 240px;
  margin-right: 12px;
}